// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

const toolbar = [
  'fontselect',
  // "preview searchreplace code | hr bullist numlist | link image charmap anchor pagebreak | insertdatetime media table emoticons | fullscreen",
  "undo redo | formatselect | fontselect | fontsizeselect | bold italic underline forecolor backcolor strikethrough | alignleft aligncenter alignright outdent indent |  blockquote removeformat subscript superscript codesample"
];

export default toolbar;
