<template>
  <div class="page-wrapper">
    <!-- <el-tabs
      v-model="editableTabsValue"
      type="card"
      :addable="false"
      closable
      @edit="handleTabsEdit"
    >
      <el-tab-pane
        v-for="(id, index) in currentForms"
        :label="`[${index + 1}] - 编辑表单 ${id}`"
        :key="id"
        :name="`${id}`"
      >
        <ProductForm :productId="id" />
      </el-tab-pane>
    </el-tabs> -->
    <ProductForm :productId="currentId" />
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";

import ProductForm from "./components/ProductForm.vue";
export default {
  name: "ProductFormPage",
  components: { ProductForm },
  data() {
    return {
      currentId: null,
      editableTabsValue: "",
      currentForms: [],
    };
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "ProductFormPage") {
          if (this.$route.params.id && !isEmprty(this.$route.params.id)) {
            this.currentId = this.$route.params.id;
            // if (!this.currentForms.includes(this.$route.params.id)) {
            //   this.currentForms.push(this.$route.params.id);
            // }
            // this.editableTabsValue = `${this.$route.params.id}`;
          } else {
            // this.currentForms.push(null);
            // this.editableTabsValue = "null";
            this.currentId = null;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleTabsEdit(targetName, action) {
      if (action == "add") {
        return;
      }
      this.$confirm("表单不会自动被保存的哦~你确定要关闭吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.currentForms = this.currentForms.filter(
            (item) => item != targetName
          );
          if (this.currentForms.length > 0) {
            this.editableTabsValue = String(
              this.currentForms[this.currentForms.length - 1]
            );
          } else {
            this.$router.push({ name: "ProductsList" });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  box-sizing: border-box;
  padding: 0 5px 0 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f1f1f1;
}
::v-deep {
  .img-upload-wrapper {
    width: 100px;
    height: 100px;
  }
  .el-tabs__header.is-top {
    margin: 0 !important;
  }
  .el-tabs__content {
    height: calc(100% - 52px);
  }
  .el-tabs__header {
    background-color: #fff;
  }
}
</style>
