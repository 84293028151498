<template>
  <div class="form-wrap">
    <div class="form-container">
      <el-form
        :model="form"
        ref="form"
        v-loading="loading"
        label-width="auto"
        :rules="rules"
        class="wg-product-ruleForm"
        label-position="top"
        :disabled="submitting"
      >
        <FormBlock name="基本信息">
          <el-row :gutter="10">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="英文名称" prop="nameEn" class="base-input">
                <el-input
                  v-model="form.nameEn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="中文名称" prop="nameCn" class="base-input">
                <el-input
                  v-model="form.nameCn"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="编号" prop="code" class="base-input">
                <el-input
                  v-model="form.code"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="联长" prop="lengthDesc" class="base-input">
                <el-input
                  v-model="form.lengthDesc"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="价格（美元）"
                prop="price"
                class="base-input"
              >
                <el-input
                  v-model="form.price"
                  type="number"
                  clearable
                  placeholder="请输入"
                  ><template slot="prepend">$</template></el-input
                >
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="piecesPerCarton"
                prop="price"
                class="base-input"
              >
                <el-input
                  v-model="form.piecesPerCarton"
                  type="number"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="sqmPerCarton"
                prop="price"
                class="base-input"
              >
                <el-input
                  v-model="form.sqmPerCarton"
                  type="number"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="kgsPerCarton"
                prop="price"
                class="base-input"
              >
                <el-input
                  v-model="form.kgsPerCarton"
                  type="number"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="cartonsPerPallet"
                prop="price"
                class="base-input"
              >
                <el-input
                  v-model="form.cartonsPerPallet"
                  type="number"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="系列"
                prop="seriesIds"
                class="custom-form-select"
              >
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.seriesIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicSeriesList,
                  }"
                />
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="材质"
                prop="materialIds"
                class="custom-form-select"
              >
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.materialIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicMaterialsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="厚度"
                prop="heightIds"
                class="custom-form-select"
              >
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.heightIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicHeightsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item
                label="工艺"
                prop="techniqueIds"
                class="custom-form-select"
              >
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.techniqueIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicTechniquesList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" class="custom-form-select">
              <el-form-item label="规格" prop="specificationIds">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.specificationIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicSpecificationsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" class="custom-form-select">
              <el-form-item label="风格" prop="styleIds">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.styleIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicStylesList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8" class="custom-form-select">
              <el-form-item label="色系" prop="colorIds">
                <BasicAsyncSelect
                  multiple
                  keyName="nameCn"
                  valueName="id"
                  v-model="form.colorIds"
                  :filterKeyName="{
                    disabled: false,
                  }"
                  customKeyName
                  :keyNames="['nameCn', 'nameEn']"
                  :asyncObj="{
                    dataFun: GetMosaicColorsList,
                  }"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="次序（数值越大越靠前）" prop="seq">
                <el-input type="number" v-model="form.seq" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="推荐" prop="star">
                <el-switch
                  v-model="form.star"
                  active-text="推荐产品"
                  inactive-text="常规产品"
                >
                </el-switch>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="首页展示" prop="showOnIndex">
                <el-switch
                  v-model="form.showOnIndex"
                  active-text="首页展示"
                  inactive-text="非首页展示"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </FormBlock>

        <FormBlock name="封面/海报/图片集">
          <el-row :gutter="10">
            <!-- <el-col :span="6">
              <el-form-item label="缩略图" prop="thumbnailUrl">
                <SingleImgUpload v-model="form.thumbnailUrl" />
              </el-form-item>
            </el-col> -->
            <el-col :span="6">
              <el-form-item label="封面图" prop="coverImageUrl">
                <SingleImgUpload v-model="form.coverImageUrl" />
              </el-form-item>
            </el-col>
            <el-col :span="6" v-if="false">
              <el-form-item label="效果图" prop="renderingImageUrl">
                <SingleImgUpload v-model="form.renderingImageUrl" />
              </el-form-item>
            </el-col>

            <!-- <el-col :span="6">
              <el-form-item label="海报图" prop="posterImageUrl">
                <SingleImgUpload v-model="form.posterImageUrl" />
              </el-form-item>
            </el-col> -->

            <!-- <el-col :span="12">
              <el-form-item label="海报标语" prop="posterSlogan">
                <el-input
                  v-model="form.posterSlogan"
                  clearable
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="24" v-if="false">
              <el-form-item label="元素图(显示顺序从左到右)" prop="medias">
                <DraggbleSort
                  syncSortKey
                  v-model="form.elementMedias"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      tips="选择图片"
                      v-model="slotProps.item.url"
                      @remove="removeElementMedias(slotProps.index)"
                    />
                    <div
                      class="video-medias-item"
                      v-if="slotProps.item.typeId == 'VIDEO'"
                    >
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择封面图"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearElementMediasVideoCover(slotProps.index)"
                      />

                      <ProductVideoItem
                        v-if="slotProps.item.typeId == 'VIDEO'"
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />

                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removeElementMedias(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addElementMedias('IMAGE')"
                      >添加图片</el-button
                    >
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="addElementMedias('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button> -->
                  </el-button-group>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="实拍图(显示顺序从左到右)" prop="photos">
                <DraggbleSort
                  syncSortKey
                  v-model="form.photos"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      tips="选择图片"
                      v-model="slotProps.item.url"
                      @remove="removePhotos(slotProps.index)"
                    />
                    <div
                      class="video-medias-item"
                      v-if="slotProps.item.typeId == 'VIDEO'"
                    >
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择封面图"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearPhotosVideoCover(slotProps.index)"
                      />

                      <ProductVideoItem
                        v-if="slotProps.item.typeId == 'VIDEO'"
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />

                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removePhotos(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addPhotos('IMAGE')"
                      >添加图片</el-button
                    >
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="addPhotos('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button> -->
                  </el-button-group>
                </div>
                <!-- <ProductMediaUpload /> -->
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="false">
              <el-form-item
                label="媒体数据集合(显示顺序从左到右)"
                prop="medias"
              >
                <DraggbleSort
                  syncSortKey
                  v-model="form.normalMedias"
                  class="medias-list-wrap"
                >
                  <template v-slot:default="slotProps">
                    <ProductImgUpload
                      v-if="slotProps.item.typeId == 'IMAGE'"
                      class="medias-item"
                      v-model="slotProps.item.url"
                      @remove="removeMedias(slotProps.index)"
                    />
                    <div
                      v-if="slotProps.item.typeId == 'VIDEO'"
                      class="video-medias-item"
                    >
                      <ProductVideoItem
                        :showDeleteIcon="false"
                        class="medias-item"
                        v-model="slotProps.item.url"
                      />
                      <ProductImgUpload
                        class="medias-item"
                        tips="选择图片"
                        v-model="slotProps.item.coverImageUrl"
                        @remove="clearVideoCover(slotProps.index)"
                      />
                      <i
                        class="el-icon-delete remove-video-icon"
                        title="点击删除"
                        @click="removeMedias(slotProps.index)"
                      ></i>
                    </div>
                  </template>
                </DraggbleSort>
                <div class="add-madias-item">
                  <el-button-group>
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-picture-outline"
                      @click="addMedias('IMAGE')"
                      >添加图片</el-button
                    >
                    <!-- <el-button
                      type="primary"
                      size="small"
                      @click="addMedias('VIDEO')"
                      >添加视频<i
                        class="el-icon-video-camera-solid el-icon--right"
                      ></i
                    ></el-button> -->
                  </el-button-group>
                </div>
                <!-- <ProductMediaUpload /> -->
              </el-form-item>
            </el-col>
          </el-row>
        </FormBlock>
        <!-- <FormBlock name="产品简介">
          <el-form-item prop="introduction" v-if="showRichEditor">
            <Tinymce v-model="form.introduction" :height="400" />
          </el-form-item>
        </FormBlock> -->
        <FormBlock name="产品图文详情">
          <el-form-item prop="description" v-if="showRichEditor">
            <Tinymce v-model="form.description" :height="800" />
          </el-form-item>
        </FormBlock>
      </el-form>
    </div>
    <div class="control-btn-group">
      <el-button
        @click="onCancel"
        v-if="isEmprty(this.currentId)"
        :disabled="submitting"
        >重 置</el-button
      >
      <el-button
        v-if="isEmprty(this.currentId)"
        type="primary"
        :loading="submitting"
        @click="submitForm('form')"
        >保存新增</el-button
      >
      <el-button
        v-if="!isEmprty(this.currentId)"
        type="warning"
        :loading="submitting"
        @click="submitEditForm('form')"
        >保存修改</el-button
      >
    </div>
  </div>
</template>

<script>
import { isEmprty } from "@/utils/validate";
import { createUniqueString, compare } from "@/utils/common";
import {
  PostMosaicProducts,
  PutMosaicProductsById,
  GetMosaicProductsById,
} from "../api";
import { ShowApiError } from "@/request/error";
// import BasicSelect from "@/components/BasicSelect";
import DraggbleSort from "@/components/DraggbleSort";
import SingleImgUpload from "@/components/SingleImgUpload";
// import ImgUpload from "@/components/ImgUpload/ImgUpload";
import FormBlock from "@/components/FormBlock";
import Tinymce from "@/components/Tinymce";
import ProductVideoItem from "@/components/ProductVideoItem";
import ProductImgUpload from "@/components/ProductImgUpload";
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import { GetMosaicColorsList } from "@/views/productColors/api";
import { GetMosaicMaterialsList } from "@/views/productMaterials/api";
import { GetMosaicSpecificationsList } from "@/views/productSpecifications/api";
import { GetMosaicStylesList } from "@/views/productStyles/api";
import { GetMosaicTechniquesList } from "@/views/productTechniques/api";
import { GetMosaicSeriesList } from "@/views/productSeries/api";
import { GetMosaicHeightsList } from "@/views/productHeights/api";
export default {
  name: "ProductForm",
  props: {
    productId: {
      type: [String, Number],
      default: null,
    },
  },
  components: {
    FormBlock,
    SingleImgUpload,
    BasicAsyncSelect,
    // ImgUpload,
    // BasicSelect,
    Tinymce,
    DraggbleSort,
    ProductVideoItem,
    ProductImgUpload,
  },
  data() {
    return {
      currentId: null,
      loading: false,
      submitting: false,
      showAddMediaDialog: false,
      showRichEditor: true,
      focusIndex: 0,
      form: {},
      rules: {
        nameEn: [
          { required: true, message: "请输入英文名称", trigger: "blur" },
        ],
        nameCn: [
          { required: true, message: "请输入中文名称", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    productId: {
      handler(value) {
        if (!isEmprty(value)) {
          this.currentId = value;
          this.getFormData();
        } else {
          this.currentId = null;
          this.initForm();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.initForm();
  },
  methods: {
    isEmprty,
    createUniqueString,
    GetMosaicColorsList,
    GetMosaicMaterialsList,
    GetMosaicSpecificationsList,
    GetMosaicStylesList,
    GetMosaicTechniquesList,
    GetMosaicSeriesList,
    GetMosaicHeightsList,
    formatForm() {
      return {
        ...this.form,
        code: this.form.code == "" ? null : this.form.code,
        photos: this.form.photos.map((item) => {
          return {
            coverImageUrl: item.coverImageUrl,
            typeId: item.typeId,
            seq: item.seq,
            url: item.url,
          };
        }),
        normalMedias: this.form.normalMedias.map((item) => {
          return {
            coverImageUrl: item.coverImageUrl,
            typeId: item.typeId,
            seq: item.seq,
            url: item.url,
          };
        }),
        elementMedias: this.form.elementMedias.map((item) => {
          return {
            coverImageUrl: item.coverImageUrl,
            typeId: item.typeId,
            seq: item.seq,
            url: item.url,
          };
        }),
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostMosaicProducts([this.formatForm()])
            .then(() => {
              this.submitting = false;
              this.initForm();

              this.$message.success("新增成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError(err, "批量新增异常");
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    submitEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutMosaicProductsById(this.currentId, this.formatForm())
            .then(() => {
              this.submitting = false;
              this.$message.success("修改成功");
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError(err, "批量新增异常");
            });
        } else {
          this.$message.warning("表单有误，请检查");
          return false;
        }
      });
    },
    getFormData() {
      this.loading = true;
      GetMosaicProductsById(this.currentId)
        .then((res) => {
          let _data = res.data;
          this.initForm();
          this.reviewFormByData(_data);
          this.showRichEditor = true;
          this.loading = false;
        })

        .catch((err) => {
          this.loading = false;
          ShowApiError(err, "获取表单数据失败");
        });
    },
    reviewFormByData(_data = {}) {
      this.form = {
        code: _data.code,
        nameCn: _data.nameCn,
        nameEn: _data.nameEn,
        lengthDesc: _data.lengthDesc,
        price: _data.price,
        piecesPerCarton: _data.piecesPerCarton,
        sqmPerCarton: _data.sqmPerCarton,
        kgsPerCarton: _data.kgsPerCarton,
        cartonsPerPallet: _data.cartonsPerPallet,
        introduction: _data.introduction,
        description: _data.description,
        photos: this.sortArr(_data.photos),
        elementMedias: this.sortArr(_data.elementMedias),
        normalMedias: this.sortArr(_data.normalMedias),
        materialIds: this.formatDataToids(_data.materials),
        techniqueIds: this.formatDataToids(_data.techniques),
        specificationIds: this.formatDataToids(_data.specifications),
        styleIds: this.formatDataToids(_data.styles),
        colorIds: this.formatDataToids(_data.colors),
        seriesIds: this.formatDataToids(_data.series),
        heightIds: this.formatDataToids(_data.heights),
        thumbnailUrl: _data.thumbnailUrl,
        renderingImageUrl: _data.renderingImageUrl,
        coverImageUrl: _data.coverImageUrl,
        posterImageUrl: _data.posterImageUrl,
        posterSlogan: _data.posterSlogan,
        seq: _data.seq,
        star: _data.star,
        showOnIndex: _data.showOnIndex,
      };
    },
    sortArr(data = []) {
      if (data.length <= 0) {
        return data;
      }
      return data.sort(compare("seq"));
    },
    formatDataToids(data = []) {
      if (data.length <= 0) {
        return data;
      }
      return data.map((item) => item.id);
    },
    initForm() {
      this.form = {
        code: null,
        nameCn: "",
        nameEn: "",
        lengthDesc: "",
        price: null,
        piecesPerCarton: null,
        sqmPerCarton: null,
        kgsPerCarton: null,
        cartonsPerPallet: null,
        introduction: "",
        description: "",
        posterImageUrl: "",
        posterSlogan: "",
        coverImageUrl: "",
        renderingImageUrl: "",
        thumbnailUrl: "",
        star: false,
        showOnIndex: false,
        seq: 0,
        normalMedias: [],
        elementMedias: [],
        photos: [],
        materialIds: [],
        techniqueIds: [],
        specificationIds: [],
        styleIds: [],
        colorIds: [],
        seriesIds: [],
        heightIds: [],
      };
      this.resetRichComponent();
      this.$nextTick(() => {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate();
        }
      });
    },
    resetRichComponent() {
      this.showRichEditor = false;
      let timer = null;
      timer = setTimeout(() => {
        clearTimeout(timer);
        this.showRichEditor = true;
      }, 1000);
    },

    // 媒体
    addMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.normalMedias.length,
        typeId: type,
        url: "",
      };
      this.form.normalMedias.push(item);
    },
    removeMedias(index) {
      this.form.normalMedias.splice(index, 1);
    },

    clearVideoCover(index) {
      this.form.normalMedias = this.form.normalMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    // 元素图
    addElementMedias(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.elementMedias.length,
        typeId: type,
        url: "",
      };
      this.form.elementMedias.push(item);
    },
    removeElementMedias(index) {
      this.form.elementMedias.splice(index, 1);
    },
    clearElementMediasVideoCover(index) {
      this.form.elementMedias = this.form.elementMedias.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    // 实拍图
    addPhotos(type) {
      let item = {
        coverImageUrl: "",
        seq: this.form.photos.length,
        typeId: type,
        url: "",
      };
      this.form.photos.push(item);
    },
    removePhotos(index) {
      this.form.photos.splice(index, 1);
    },
    clearPhotosVideoCover(index) {
      this.form.photos = this.form.photos.map((m, i) => {
        if (i == index) {
          m.coverImageUrl = "";
        }
        return m;
      });
    },
    onCancel() {
      this.initForm();
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.wg-product-ruleForm {
  box-sizing: border-box;
  width: 100%;
  .icon-btn {
    font-size: 20px;
    padding: 5px 10px;
    cursor: pointer;
  }
  .base-input {
    ::v-deep {
      .el-form-item__content {
        height: 40px;
        overflow: hidden;
      }
      .el-input__inner {
        height: 40px;
      }
    }
  }
}
.form-tips {
  font-size: 13px;
  text-align: left;
  padding: 10px;
}
.box-card {
  margin-bottom: 10px;
  &:hover {
    background-color: #f1f1f1;
  }
}
.medias-list-wrap {
  display: inline-block;
  max-width: 100%;
  ::v-deep {
    .transition-el {
      max-height: none;
    }
  }
  .medias-item {
    display: block;
    width: 100px;
    height: 100px;
    margin: 5px;
  }
  .video-medias-item {
    box-sizing: border-box;
    padding: 5px 0;
    margin: 5px;
    width: 280px;
    height: 100px;
    position: relative;
    padding-right: 20px;
    background-color: #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .remove-video-icon {
      position: absolute;
      bottom: 5px;
      right: 2px;
      font-size: 16px;
      cursor: pointer;
      color: #c51515;
    }
    ::v-deep {
      .basic-upload-wrap {
        width: 150px;
        height: 90px;
      }
      .img-upload-wrap {
        width: 90px;
        height: 90px;
      }
    }
  }
}
.add-madias-item {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 30px;
  margin: 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}
.models-list-wrap {
  width: 100%;
  max-width: 100%;
  .box-card {
    width: auto;
    cursor: move;
  }
  ::v-deep {
    .transition-el {
      box-sizing: border-box;
      flex-direction: row;
      max-height: 100%;
    }
  }
}

.form-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  .custom-form-select {
    ::v-deep {
      .el-form-item__content {
        max-height: 40px;
      }
    }
  }

  .form-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-bottom: 70px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .control-btn-group {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    z-index: 2006;
    text-align: right;
  }
}
</style>
